<template>
  <section id="courses" class="bg-[#faf9ff] py-28">
    <div class="max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] mx-auto lg:px-0 px-4">
      <div class="title text-black text-center">
        Individual
        <vue-typewriter-effect
          :strings="['Programmes','Courses']"
          class="text-primary-purple"
        />
      </div>

      <hr class="w-1/2 mx-auto" />

      <!-- =============+++++++++++++++++++++++++++++++++++++++++++++============================================ -->
      <div class="mt-8 h-full py-3">
        <Carousel :value="courses" :numVisible="numVisible" :numScroll="1" :autoplayInterval="interval" @mouseenter="interval = 15000" @mouseleave="interval = 3000">
          <template #item="slotProps">
            <a :href="`/course/${slotProps.data.id}`" class="course-item block">
              <img :src="slotProps.data.image" :alt="slotProps.data.title" class="w-full h-48 rounded-t-lg object-cover" />
              <div class="mt-4 px-3">
                <h3 class="text-sm font-semibold h-[60px]  text-left  ">{{ slotProps.data.title }}</h3>
                <div class="flex justify-start gap-4 items-center">
                  <p class="text-gray-500 mt-2"><i class="pi pi-clock" style="font-size:0.75rem"></i> {{ slotProps.data.duration }}</p>
                  <p class="text-gray-500 mt-2 flex justify-start items-center"> <LevelBarIconComp :type='slotProps.data.level' /> {{ slotProps.data.level }}</p>
                </div>
                <p class="text-gray-500 mt-2 text-left"><i class="pi pi-briefcase" style="font-size: 0.75rem"></i> {{ slotProps.data.projects }} Projects</p>
                <p class="text-primary-orange mt-2 text-left "><i class="pi pi-star-fill" style="font-size: 0.75rem"></i> <i class="pi pi-star-fill" style="font-size: 0.75rem"></i> <i class="pi pi-star-fill" style="font-size: 0.75rem"></i> <i class="pi pi-star-fill" style="font-size: 0.75rem"></i> <i class="pi pi-star-half-fill" style="font-size: 0.75rem"></i> </p>
                <hr />
                <a :href="`/course/${slotProps.data.id}`" class="flex items-center justify-end">
                  <div class="flex justify-start items-center gap-2 py-2 px-4 w-fit my-3  bg-[#EDEAFF] text-purple-950 font-bold text-sm rounded-md">
                    <div class="">View Details</div>
                    <i class="pi pi-arrow-right" style="font-size: 0.5rem"></i>
                  </div>
                </a>
              </div>
            </a>
          </template>
        </Carousel>
      </div>


      <a href="/course" class="w-[271px]  h-[56px] mx-auto flex justify-center gap-6 items-center mt-10 rounded-[99px] bg-primary-border text-white hover:scale-105">
        <div>See more course</div>
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_979_4666)">
          <rect opacity="0.6" x="2.75" y="2.25" width="13.5" height="13.5" rx="1.5" stroke="#74DCFF" stroke-width="0.03"/>
          <rect opacity="0.6" x="2" y="1.5" width="15" height="15" rx="7.5" stroke="#A5A5A5" stroke-width="0.03"/>
          <rect opacity="0.6" x="5.75" y="5.25" width="7.5" height="7.5" rx="3.75" stroke="#A5A5A5" stroke-width="0.03"/>
          <rect opacity="0.6" x="2" y="3" width="15" height="12" rx="1.5" stroke="#FFA800" stroke-width="0.03"/>
          <rect opacity="0.6" x="3.5" y="16.5" width="15" height="12" rx="1.5" transform="rotate(-90 3.5 16.5)" stroke="#F79DFF" stroke-width="0.03"/>
          <path opacity="0.6" d="M18.5 0L0.5 18" stroke="#A5A5A5" stroke-width="0.03"/>
          <path opacity="0.6" d="M0.5 0L18.5 18" stroke="#A5A5A5" stroke-width="0.03"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.436 -0.264273C11.0763 -0.683322 10.445 -0.731396 10.0259 -0.371651C9.60686 -0.0119053 9.55878 0.619432 9.91853 1.03848L15.4685 7.50338H1.3871C0.834812 7.50338 0.387097 7.9511 0.387097 8.50338C0.387097 9.05567 0.834812 9.50338 1.3871 9.50338H15.4689L9.91853 15.9687C9.55878 16.3877 9.60686 17.0191 10.0259 17.3788C10.445 17.7386 11.0763 17.6905 11.436 17.2714L18.4039 9.15496C18.7255 8.78028 18.7255 8.22689 18.4039 7.85221L11.436 -0.264273Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_979_4666">
          <rect x="0.5" width="18" height="18" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          
        </a>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import LevelBarIconComp from '../../components/IconComp/LevelBarIconComp.vue';
import VueTypewriterEffect from "vue-typewriter-effect";
import Carousel from 'primevue/carousel';
import { CourseApi } from '@/services';

interface Course {
  id: string;
  category: string[];
  image: string;
  title: string;
  duration: string;
  level: string;
  projects: string;
  rating: string;
}


const courses =ref<Course[]>([]);

onMounted(async () => {
  try {
    const response:any = await CourseApi.allCourses(); // Replace with your API endpoint
    courses.value =  response
    console.log('object', courses.value);
    // Update courses data with fetched data
  } catch (error) {
    console.error('Error fetching courses:', error);
    // Handle error gracefully, e.g., show an error message
  }
});

const numVisible = ref(4);
const interval = ref(3000);

onMounted(() => {
  updateNumVisible();
});

// Update numVisible based on screen size
const updateNumVisible = () => {
  if (window.innerWidth < 468) {
    numVisible.value = 1;
  } else if (window.innerWidth >= 768 && window.innerWidth < 1023) {
    numVisible.value = 2;
  } else {
    numVisible.value = 4;
  }
};
updateNumVisible();
// Watch for window resize and update numVisible
watch(() => window.innerWidth, updateNumVisible);
updateNumVisible();

</script>

<style scoped>
.course-item {
  margin: 10px;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  padding: 0 2px 3px 0;
  width: 284px;
}
.course-item:hover {
  transform: scale(1.05);
}

@media (max-width: 767px) {
   .course-item {
    width: 294px;
    margin: 4px;
  }
  .p-carousel .p-carousel-viewport .p-carousel-viewport-content {
    display: flex !important;
  }
  .p-carousel .p-carousel-viewport .p-carousel-viewport-content .p-carousel-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
 
}
</style>
