<template>
    <Navbar />
    <section class="sm:mb-[40px] mb-[20px] sm:mt-[193px] mt-[128px] md:mt-[138px]">
      <div
        class="max-w-[95vw] sm:max-w-[754px] sm:mx-[2.5vw] md:mx-auto xl:max-w-[1280px] border-2 h-fit mx-auto bg-[#FDF5FF] py-10 lg:py-14 rounded-[18px] xl:rounded-[30px] flex flex-col items-center justify-center"
      >
        <p
          class="xl:w-[120px] xl:h-[43px] text-xs xl:text-base border border-border-grey w-[85px] h-[25px] mb-[14px] xl:mb-6 rounded-full bg-white text-[#666D80] flex items-center justify-center"
        >
          Blog Spot
        </p>
        <div
          class="w-[346px] max-w-full sm:w-[564px] xl:w-[957px] text-center  font-semibold exo text-2xl text-text-black sm:text-[28px] xl:text-5xl"
        >
          CAMLDS Digest
        </div>
        <div
          class="mt-4 xl:mt-8 w-[346px] max-w-full sm:w-[564px] xl:w-[957px] text-xs xl:text-base text-[#7B7B7B] exo block mx-auto text-center font-normal"
        >
        Welcome to CAMLDS digest, your source for insights, trends, and inspiring stories. Explore expert advice, practical tips, and a community driven by passion and discovery. Join us to learn, grow, and succeed together in this educational hub.
        </div>
      </div>
    </section>
  
    <!-- Blog Posts -->
    <section
      v-if="!isLoading"
      class="flex justify-start items-center sm:gap-[54px] gap-[25px] flex-wrap mt-[52px] mb-20 mx-auto xl:max-w-[1280px]"
    >
      <div
        v-for="post in blogPosts"
        :key="post.post_id"
        class="!min-h-[375px] w-[389px] rounded-[10px] shadow-md sm:mx-auto mx-2 sm:mb-10 mb-4 hover:scale-105"
      >
        <router-link :to="'/blog/post/' + post.post_id">
          <img :src="post?.img_link" class="!h-[192px] bg-gray-200 w-full rounded-t-[10px] object-cover" />
  
          <div class="my-4 flex gap-5 items-center justify-start text-[12px] text-text-dull-grey font-medium px-3">
            <div class="text-[12px] text-text-dull-grey font-medium">
              {{ new Date(post.date).toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" }) }}
            </div>
  
            <div>
              {{
                calculateReadingTime(post?.content) <= 1
                  ? `${calculateReadingTime(post?.content)} min read`
                  : `${calculateReadingTime(post?.content)} mins read`
              }}
            </div>
          </div>
  
          <div class="font-bold text-[18px] w-[337px] exo max-h-[44px] mb-3 text-primary-blue px-3">
            {{ post?.title.length > 60 ? post?.title.substring(0, 60) + "..." : post.title }}
          </div>
  
          <div
          v-html="post?.content.length > 120 ? post?.content.substring(0, 120) + '...' : post.content"
          class="!font-normal w-[337px] mb-3 !text-sm content h-[70px] overflow-hidden px-3"
        ></div>
  
          <div class="text-sm flex justify-between items-center gap-2 px-3">
            <div class="flex justify-start items-center gap-2">
              <div class="w-5 h-5 rounded-full bg-gray-200 text-center flex justify-center items-center">
                <img
                  src="../../../public/img/icons/android-icon-192x192.png"
                  alt="C"
                />
              </div>
              <div class="text-xs exo">CAMLDS Team</div>
  
              <div class="flex justify-start items-center gap-1 my-3">
                <div class="text-primary-purple font-bold ">{{ formatClapCount(post.clap_count) }}</div>
                <div>
                    <ClapIconComp class="sm:h-6 sm:w-6 h-4 w-4 sm:-mt-2 -mt-1" title="Clap" />
                </div>
              </div>
            </div>
  
            <!-- <ArrowRightIconComp /> -->
          </div>
        </router-link>
      </div>
    </section>
  
    <div v-if="isLoading" class="min-h-[60vh] flex justify-center items-start " > <div class="loader"></div></div>
  
    <div v-if="apiCallFailed && !isLoading" @clicked="reload">Unable to load blog posts right now</div>
  
    <!-- <RetryLoaderComp v-if="blogPosts?.count == 0" text="No blog post yet !" @clicked="reload" /> -->
  
    <Paginator
      class="!mt-3"
      :pt="{
        root: {
          class: ' !w-full text-xs p-0',
        },
      }"
      v-if="blogPosts && blogPosts.count != 0"
      :totalRecords="blogPosts?.length"
      :rows="30"
      @page="handlePage"
      template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    />
    <FooterSection />
  </template>
  
  <script setup lang="ts">
import Navbar from "../HomeView/Navbar.vue";
import FooterSection from "../HomeView/FooterSection.vue";
import ArrowRightIconComp from '@/components/IconComp/ArrowRightIconComp.vue'

//   import { ArrowRightIconComp, ClapIconComp } from "@/components/IconsComp";
  import Paginator from "primevue/paginator";
  import { BlogApi } from "@/services";
//   import { useMessageStore } from "@/stores";
  import { onMounted, ref } from "vue";
  import ClapIconComp from '@/components/IconComp/ClapIconComp.vue'
//   import LoadingBlog from "../../views/BlogView/LoadingBlog.vue";
//   import RetryLoaderComp from "@/components/RetryLoaderComp";
  
//   const messageStore = useMessageStore();
  const blogPosts = ref<any>(null);
  const isLoading = ref(false);
  const apiCallFailed = ref(false);
  
  let readingTime = ref<number>(0);
  
  const calculateReadingTime = (text: string) => {
    const wordsPerMinute = 200; // Average reading speed in words per minute
    const words = text.split(" ").length;
    return (readingTime.value = Math.ceil(words / wordsPerMinute));
  };
  
  const page = ref(1);
  const handlePage = (event: any) => {
    page.value = event.page + 1;
  };
  
  function reload() {
    window.location.reload();
  }
  
  onMounted(async () => {
    try {
      isLoading.value = true;
      const blogReq:any = await BlogApi.getBlogPosts();
      blogReq.results?.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
      blogPosts.value = blogReq.results;
      console.log(blogPosts.value);
      isLoading.value = false;
    } catch (error) {
      apiCallFailed.value = true;
    //   messageStore.setMessage("Error loading posts", "error");
    }
  });
  
  function formatClapCount(num: number): string {
    if (num >= 1000000000 && num < 1000000000000) {
      const rounded = Math.round(num / 100000000) / 10;
      return rounded.toFixed(1) + "B";
    } else if (num >= 1000000 && num < 1000000000) {
      const rounded = Math.round(num / 100000) / 10;
      return rounded.toFixed(1) + "M";
    } else if (num >= 1000 && num < 1000000) {
      const rounded = Math.round(num / 100) / 10;
      return rounded.toFixed(1) + "k";
    }
    return num.toString();
  }
  </script>
  <style scoped>

  .loader {
    width: 60px;
    aspect-ratio: 1;
    display: flex;
    animation: l8-0 2s infinite sptes(1);
  }
  .loader::before,
  .loader::after {
    content: "";
    flex: 1;
    animation: 
      l8-1 1s infinite linear alternate,
      l8-2 2s infinite steps(1) -.5s;
  }
  .loader::after {
    --s:-1,-1;
  }
  @keyframes l8-0 {
    0%  {transform: scaleX(1)  rotate(0deg)}
    50% {transform: scaleX(-1) rotate(-90deg)}
  }
  @keyframes l8-1 {
    0%,
    5%   {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateY(0deg) }
    33%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(0deg) }
    66%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(-180deg)}
    95%,
    100% {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateX(-180deg)}
  }
  @keyframes l8-2 {
    0%  {background:#FEAE49}
    50% {background:#581699}
  }

</style>
  