import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView/HomeView.vue'
import BecomeAnInstructor from '@/views/BecomeAnInstructorView/BecomeAnInstructor.vue'
import CourseDetail from '@/views/CourseDetailView/CourseDetail.vue'
import Course from '@/views/CourseView/Course.vue'
import EnrollNow from '@/views/EnrollNow/EnrollNow.vue'
import BlogView from '@/views/BlogView/BlogView.vue'
import SingleBlogPost from '@/views/BlogView/SingleBlogPost.vue'
import ContactView from '@/views/ContactView/ContactView.vue'
import CampusNetworkView from '@/views/CampusNetwork/CampusNetworkView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: "/course",
    name: "course",
    component: Course,
  },
  {
    path: "/course/:id/",
    name: "courseDetail",
    component: CourseDetail,
  },
  {
    path: '/become-an-instructor',
    name: 'Become An Instructor',
    component: BecomeAnInstructor
  },
  {
    path: '/enroll',
    name: 'Enroll',
    component: EnrollNow
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView
  },
  {
    path: '/campus/leads',
    name: 'Campus Network Lead',
    component: CampusNetworkView
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogView
  },
  {
    path: "/blog/post/:post_id/",
    name: "SingleBlogPost",
    component: SingleBlogPost
  },
  {
    path: "/verify",
    name: "Verify",
    component: () => import("@/views/Verify/Verify.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("@/views/NotFound/NotFound.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Set the document title dynamically
  const baseName = 'CAMLDS -';
  const title = to.meta.title || to.name || 'CAMLDS';
  
  document.title = `${baseName} ${title}`;
  
  next();
});

export default router
