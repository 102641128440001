<template>
    <Navbar />
    <div class="flex items-start justify-center min-h-screen bg-[#F7EFFF] text-[#000] lg:py-[170px] py-[110px]">
    <div class="max-w-[95vw] sm:max-w-[754px] xl:max-w-[1240px] w-full text-left mx-auto flex lg:flex-row flex-col gap-16 justify-between items-start">
        <div class=" lg:w-1/2 w-full">
            <h1 class="font-bold lg:text-[52px] text-[32px] exo text-primary-purple">Contact Us</h1>
            <p class="font-bold text-xl exo">Call and Enquiry</p>
            <p class="mt-4">We provide free consultation related to the subject matter after the course. So, email, call, or complete the form to hear from us and we will get back to you asap.</p>

            <div class="mt-12">enquiry@camlds.org</div>
            <div>+234808-894-3064</div>

            <div class="mt-4 flex md:flex-row flex-col justify-between items-start gap-4 w-full">
                <div class="md:w-1/2 w-full">
                    <h2 class="font-bold text-xl">Inquires</h2>
                    <p class="mt-2">Our support team is available around the clock to address any concerns or queries that you may have.</p>
                </div>
                <div class="md:w-1/2 w-full">
                    <h2 class="font-bold text-xl ">Support</h2>
                    <p class="mt-2 text-pretty">You can cancel or re-schedule the course due to unforeseen circumstances. If the course is cancelled, we will refund 100% to participants.
                    </p>
                </div>
            </div>
            <div class="mt-12 flex md:flex-row flex-col justify-between items-start gap-4 w-full">
                <div class="md:w-1/2 w-full">
                    <h2 class="font-bold text-xl">Map</h2>
                    <p class="mt-2"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.4911127259747!2d7.424941474833931!3d9.110035290954576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104ddf888ac6a5f3%3A0x49b977de064aee51!2sCenter%20for%20Applied%20Machine%20Learning%20and%20Data%20Science!5e0!3m2!1sen!2sng!4v1722355414901!5m2!1sen!2sng" width="297" height="100" style="border:0;" allowfullscreen="false" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></p>
                </div>
                <div class="md:w-1/2 w-full">
                    <h2 class="font-bold text-xl ">Office Address</h2>
                    <p class="mt-2 text-pretty">Plot 914, Jahi District,
                        Suite 307,308 & 309 Kabaki Plaza,
                        Abuja 900108, Nigeria
                       
                    </p>
                </div>
                </div>
        </div>
        
        <div class="bg-white h-full lg:w-1/2 w-full rounded-md py-8 lg:px-8 px-4">
            <h2 class="font-bold lg:text-3xl exo text-xl">Get in Touch</h2>
            <div class="mt-2 font-[400] text-[#5b5b5b]">You can reach us anytime</div>
            <Form  v-slot="{ values, errors }"
            :validation-schema="schema" @submit="submitForm" class="mt-8 w-full">
                <div class="flex gap-4 w-full">
                    <div class="w-full">

                        <Field id="fname" name="fname" type="text" placeholder="First name" class="p-4 border border-gray-300 rounded-md w-full  focus:border-primary-purple focus:ring-2 focus:ring-primary-purple  outline-none" />
                        <ErrorMessage class="text-wrap text-red-500 text-sm" name="fname" />
                    </div>
                    <div class="w-full">

                        <Field id="lname" name="lname" type="text" placeholder="Last name" class="p-4 border border-gray-300 rounded-md w-full  focus:border-primary-purple focus:ring-2 focus:ring-primary-purple  outline-none" />
                        <ErrorMessage class="text-wrap text-red-500 text-sm" name="lname" />    
                    </div>
                </div>
                <div>

                    <Field id="email" name="email" type="email" placeholder="Email" class="p-4 border border-gray-300 rounded-md w-full mt-7  focus:border-primary-purple focus:ring-2 focus:ring-primary-purple  outline-none" /> 
                    <ErrorMessage class="text-wrap text-red-500 text-sm" name="email" />   
                </div>
                <div>

                    <Field id="phoneNumber" name="phoneNumber" type="tel" placeholder="Phone number" class="p-4 border border-gray-300 rounded-md w-full mt-7  focus:border-primary-purple focus:ring-2 focus:ring-primary-purple  outline-none" /> 
                    <ErrorMessage class="text-wrap text-red-500 text-sm" name="phoneNumber" />
                </div>
                <!-- create a textarea -->    
                <div>

                <Field as="textarea" id="message" name="message" rows="4" cols="50" class="p-4 border border-gray-300 rounded-md w-full mt-7  focus:border-primary-purple focus:ring-2 focus:ring-primary-purple  outline-none" placeholder="Your message ..." />
                <ErrorMessage class="text-wrap text-red-500 text-sm" name="message" />
            </div>
            
            <button 
            :disabled="isLoading"
            class="mt-8 bg-primary-orange text-primary-purple font-semibold p-4 rounded-[30px] lg:w-[226px] w-full hover:scale-105">
           {{isLoading? 'Sending': 'Send Message'}}
        </button>
            </Form>
 
        </div>
    </div>
   
  </div>
  <FooterSection />

</template>

<script setup lang="ts">
import Navbar from "../HomeView/Navbar.vue";
import FooterSection from "../HomeView/FooterSection.vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { FormApi } from "@/services";
import { ref, } from "vue";

const isLoading =ref(false)



const schema = yup.object({
  fname: yup.string().required("First Name is required").min(3, "First Name must be at least 3 characters"),
  lname: yup.string().required("Last Name is required").min(3, "Last Name must be at least 3 characters"),
  phoneNumber: yup.string().required("Phone Number is required").min(10, "Enter Valid Phone Number"),
  message: yup.string().required("Message is required").min(8, "Message must be at least 8 characters"), 
  email: yup.string().email().required("Please Enter a valid email address"), 
});

interface TemplateParams {
  fname: string;
  lname: string;
  email: string;
  phone_number: string;
  message: string;
}


const submitForm = async (values: any, actions: any) => {
  const { resetForm }: { resetForm: any } = actions;
  const templateParams: TemplateParams ={
    fname: values.fname,
    lname: values.lname,
    email: values.email,
    phone_number: values.phoneNumber,
    message: values.message,
    };
    try {
        isLoading.value=true
        const res =await FormApi.contactUs(templateParams as any)
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "Form succesfully Submitted ",
          });
        isLoading.value=false

          resetForm()
 
  } catch (error) {
    isLoading.value=false
   
          // console.error('FAILED...', error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "Failed to send the email. Please try again later. !",
          });
   
        }
}
</script>

<style scoped>
textarea{
    resize: none;
}
</style>